import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import Drawer, { OwnProps as DrawerProps } from '@core/components/Drawer'
import useCart from '@core/hooks/cart/useCart'
import { useTranslation } from '@core/i18n/i18n'
import { useServices } from '@core/utils/ioc'
import style from './styles.module.less'
import {
  LYSButton,
  LYSDropdown,
  LYSMenu,
  LYSModal,
} from '@core/components/Primitives'
import {
  EllipsisOutlined,
  PrinterOutlined,
  DeleteOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import Link from 'next/link'
import ProductListForm from '@core/components/Me/ProductList/ProductListDetail/ProductListForm'
import { ICreateProductList } from '@core/api/ProductLists/types'
import moment from 'moment'
import useCurrentUser from '@core/hooks/user/useCurrentUser'
export interface QuickCartDrawerProps {
  children: React.ReactNode
  className?: string
}

export const QuickCartDrawer = ({
  children,
  className,
  ...props
}: QuickCartDrawerProps & Partial<DrawerProps>) => {
  const { t } = useTranslation()
  const { toggleCartDrawer, cartDrawerOpen, closeCartDrawer } = useCart()
  const { router } = useServices()
  const {
    cart,
    deleteAllItemsFromCart,
    isCartUpdatePending,
    saveCartAsProductList,
  } = useCart()
  const { isAuthenticated } = useCurrentUser()
  const [isModalVisible, setModalVisible] = useState<boolean>(false)

  const currentDate = moment().locale('de').format('LL')
  const newProductList: ICreateProductList = {
    label: `${t('cart.title')} ${currentDate}`,
    description: '',
  }

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      if (cartDrawerOpen) {
        closeCartDrawer()
      }
    })
  }, [cartDrawerOpen, router, closeCartDrawer])

  const showDeleteAllItemsFromCartConfirm = () => {
    return LYSModal.confirm({
      title: t('cart.clearItemsMessage'),
      onOk() {
        deleteAllItemsFromCart()
      },
      cancelText: t('general.cancel'),
    })
  }

  const handleAddToProductList = () => {
    setModalVisible(true)
  }

  const handleCreateProductList = async (productList: ICreateProductList) => {
    saveCartAsProductList(productList)
    setModalVisible(false)
  }

  const actions = (
    <LYSMenu>
      <Link href={`/cart/print`} as={`/cart/print`}>
        <a target="blank">
          <LYSMenu.Item icon={<PrinterOutlined rev={undefined} />}>
            {t('cart.print')}
          </LYSMenu.Item>
        </a>
      </Link>
      {isAuthenticated && (
        <LYSMenu.Item
          icon={<SaveOutlined rev={undefined} />}
          onClick={handleAddToProductList}
          disabled={cart?.items.length === 0 || isCartUpdatePending}
        >
          {t('cart.saveAsProductList')}
        </LYSMenu.Item>
      )}
      <LYSMenu.Item
        icon={<DeleteOutlined rev={undefined} />}
        onClick={showDeleteAllItemsFromCartConfirm}
        disabled={cart?.items.length === 0 || isCartUpdatePending}
      >
        {t('cart.clearItems')}
      </LYSMenu.Item>
    </LYSMenu>
  )

  return (
    <>
      <ProductListForm
        isVisible={isModalVisible}
        onSubmit={handleCreateProductList}
        onCancel={() => setModalVisible(false)}
        initialValues={newProductList}
      />
      <Drawer
        toggleDrawer={toggleCartDrawer}
        title={
          <div className={style.drawerTitle}>
            {t('quickCart.title')}
            <LYSDropdown trigger={['click']} overlay={actions}>
              <LYSButton
                withoutPadding
                type="text"
                icon={<EllipsisOutlined rotate={90} rev={undefined} />}
              >
                {t('wishlist.wishlistContainer.options')}
              </LYSButton>
            </LYSDropdown>
          </div>
        }
        persistOnClick={true}
        className={cn(style.cartMenuDrawer, className)}
        width="400"
        open={cartDrawerOpen}
        position="right"
        {...props}
      >
        {children}
      </Drawer>
    </>
  )
}
