import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { INewsletterSubscription } from '@core/api/NewsletterSubscription/types'
import {
  subscribeNewsletter,
  unsubscribeNewsletter,
} from '@core/store/newsletter/actions'
import { useServices } from '@core/utils/ioc'

const useNewsletterSubscription = () => {
  const { cookies } = useServices()

  const [isNewsletterHidden, setNewsletterHidden] = useState<boolean>(
    !!cookies.get('hideNewsletter')
  )

  const dispatch = useDispatch()

  const subscribe = (
    data: INewsletterSubscription,
    disableNotification?: boolean
  ) => {
    dispatch(subscribeNewsletter(data, disableNotification))
  }

  const unsubscribe = (id: string) => {
    dispatch(unsubscribeNewsletter(id))
  }

  const setHide = () => {
    cookies.set('hideNewsletter', '1')
    setNewsletterHidden(true)
  }

  return {
    subscribe,
    unsubscribe,
    setHide,
    isNewsletterHidden,
  }
}

export default useNewsletterSubscription
