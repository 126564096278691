import * as React from 'react'
import { Form } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import {
  LYSButton,
  LYSCol,
  LYSInput,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import config from '@core/config/config'
import formConfig from '@core/config/formConfig'
import useNewsletterSubscription from '@core/hooks/newsletter/useNewsletterSubscription'
import { useTranslation } from '@core/i18n/i18n'
import LYSStaticImage from '../Primitives/LYSStaticImage'
import style from './index.module.less'

interface Props {
  showCloseButton?: boolean
}

const Newsletter: React.FC<Props> = ({ showCloseButton }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { subscribe, setHide, isNewsletterHidden } = useNewsletterSubscription()

  const useMailJetNewsletterSubscription =
    config.features.useMailJetNewsletterSubscription.enabled

  const mailJetWidgetUrl =
    config.features.useMailJetNewsletterSubscription.mailJetWidgetUrl

  const handleClose = () => {
    setHide()
  }

  const handleSubmit = (values: { email: string }) => {
    subscribe({
      email: values.email,
      source: config.defaultNewsletterSource,
    })
    form.resetFields()
  }

  return isNewsletterHidden ? null : useMailJetNewsletterSubscription ? (
    <LYSRow className={style.container} justify="center" align="middle">
      <iframe
        title="Newsletter"
        scrolling="no"
        className="mj-w-res-iframe"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
        src={mailJetWidgetUrl}
        width="100%"
      />
      <script
        type="text/javascript"
        src="https://app.mailjet.com/statics/js/iframeResizer.min.js"
      />
    </LYSRow>
  ) : (
    <LYSRow className={style.container} justify="center" align="middle">
      <LYSCol xs={4} className={style.mailIconContainer}>
        <LYSStaticImage
          src={config.assets.newsletterMailLogo}
          className={style.mailIcon}
          alt={'Newsletter Icon'}
          width={62}
          height={62}
        />
      </LYSCol>
      <LYSCol xs={24} className={style.text}>
        <LYSTypography.Paragraph visualAppearance={'h2'}>
          {t('footer.newsletter.title')}
        </LYSTypography.Paragraph>
        <LYSTypography.Paragraph>
          {t('footer.newsletter.text')}
        </LYSTypography.Paragraph>
      </LYSCol>
      <LYSCol>
        <Form
          form={form}
          onFinish={handleSubmit}
          className={style.inputContainer}
          {...formConfig.newsletter.email}
          aria-required={true}
        >
          <Form.Item name="email">
            <LYSInput
              aria-required={true}
              size="large"
              placeholder="E-Mail"
              type="email"
            />
          </Form.Item>
          <LYSButton size="large" htmlType="submit">
            {t('footer.subscriptionText')}
          </LYSButton>
        </Form>
      </LYSCol>
      {showCloseButton && (
        <LYSButton
          type="link"
          icon={<CloseOutlined rev={undefined} />}
          className={style.closeButton}
          onClick={handleClose}
        />
      )}
    </LYSRow>
  )
}

export default Newsletter
