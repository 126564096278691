import React from 'react'
import 'antd/lib/result/style/index.css'
// import devWarning from 'antd/lib/_util/devWarning'
import { ConfigContext } from 'antd/lib/config-provider'
import classNames from 'classnames'
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled'
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled'
import WarningFilled from '@ant-design/icons/WarningFilled'
import config from '@core/config/config'

export const IconMap = {
  success: CheckCircleFilled,
  error: CloseCircleFilled,
  info: ExclamationCircleFilled,
  warning: WarningFilled,
}

export const ExceptionMap = {
  '404': config.assets.result.notFound,
  '500': config.assets.result.serverError,
  '403': config.assets.result.unauthorized,
}

export type ExceptionStatusType = 403 | 404 | 500 | '403' | '404' | '500'
export type ResultStatusType = ExceptionStatusType | keyof typeof IconMap

export interface LYSResultProps {
  icon?: React.ReactNode
  status?: ResultStatusType
  title?: string
  subTitle?: React.ReactNode
  extraSubTitle?: React.ReactNode
  extra?: React.ReactNode
  prefixCls?: string
  className?: string
  style?: React.CSSProperties
}

// ExceptionImageMap keys
const ExceptionStatus = Object.keys(ExceptionMap)

/**
 * Render icon if ExceptionStatus includes ,render svg image else render iconNode
 *
 * @param prefixCls
 * @param {status, icon}
 */
const renderIcon = (prefixCls: string, { status, icon }: LYSResultProps) => {
  const className = classNames(`${prefixCls}-icon`)

  // devWarning(
  //   !(typeof icon === 'string' && icon.length > 2),
  //   'Result',
  //   `\`icon\` is using ReactNode instead of string naming in v4. Please check \`${icon}\` at https://ant.design/components/icon`
  // )

  if (ExceptionStatus.includes(`${status}`)) {
    const SVGImage = ExceptionMap[status as ExceptionStatusType]
    return (
      <div className={`${className} ${prefixCls}-image`}>
        <img src={SVGImage} alt={`${status}`} />
      </div>
    )
  }
  const iconNode = React.createElement(
    IconMap[status as Exclude<ResultStatusType, ExceptionStatusType>]
  )

  return <div className={className}>{icon || iconNode}</div>
}

const renderExtra = (prefixCls: string, { extra }: LYSResultProps) =>
  extra && <div className={`${prefixCls}-extra`}>{extra}</div>

export interface ResultType extends React.FC<LYSResultProps> {
  PRESENTED_IMAGE_404: React.ReactNode
  PRESENTED_IMAGE_403: React.ReactNode
  PRESENTED_IMAGE_500: React.ReactNode
}

const Result: ResultType = ({
  prefixCls: customizePrefixCls,
  className: customizeClassName,
  subTitle,
  extraSubTitle,
  title,
  style,
  children,
  status = 'info',
  icon,
  extra,
}) => {
  const { getPrefixCls, direction } = React.useContext(ConfigContext)

  const prefixCls = getPrefixCls('result', customizePrefixCls)
  const className = classNames(
    prefixCls,
    `${prefixCls}-${status}`,
    customizeClassName,
    {
      [`${prefixCls}-rtl`]: direction === 'rtl',
    }
  )
  return (
    <div className={className} style={style}>
      {renderIcon(prefixCls, { status, icon })}
      <div className={`${prefixCls}-title`}>{title}</div>
      {subTitle && (
        <div className={`${prefixCls}-subtitle`}>
          {subTitle} {extraSubTitle && extraSubTitle}{' '}
        </div>
      )}
      {renderExtra(prefixCls, { extra })}
      {children && <div className={`${prefixCls}-content`}>{children}</div>}
    </div>
  )
}

Result.PRESENTED_IMAGE_403 = ExceptionMap['403']
Result.PRESENTED_IMAGE_404 = ExceptionMap['404']
Result.PRESENTED_IMAGE_500 = ExceptionMap['500']

export default Result
