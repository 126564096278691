import { CommonProductRules } from '@core/utils/models/product/types'
import { DefaultRulesOptions } from '@original/utils/models/product/defaultRules'

export * from '@original/utils/models/product/defaultRules'

/* Customizations:
 * - isPurchasable ignores isOutOfStock
 */
export const getDefaultRules = (
  options: DefaultRulesOptions
): CommonProductRules => {
  const isPurchasable =
    options.isPurchasable && options.hasPrice && !options.isDiscontinued

  const isAllowedToAddToProductList =
    options.hasPrice && !options.isDiscontinued

  const hideStockInformation = !options.hasPrice || options.isDiscontinued

  const displayNoPriceHint = !options.hasPrice && !options.isDiscontinued

  return {
    isPurchasable,
    isAllowedToAddToProductList,
    hideStockInformation,
    displayNoPriceHint,
  }
}
