import { CustomerTypes } from '@core/api/Channel/types'
import validators from '@core/config/validators'
import { i18n } from '@core/i18n/i18n'
import coreFormConfig, {
  generalValidationRules,
} from '@original/config/formConfig'
import { Rule } from 'antd/lib/form'

const formConfig = {
  ...coreFormConfig,
  billingAddressForm(customerType: CustomerTypes, isAuthenticated?: boolean) {
    const coreBillingAddressFormConfig = coreFormConfig.billingAddressForm(
      customerType,
      isAuthenticated
    )
    return {
      ...coreBillingAddressFormConfig,
      label: {
        ...coreBillingAddressFormConfig.label,
        rules: [],
        initialValue: i18n.t('firstCheckout.billingLabel'),
      },
      firstName: {
        ...coreBillingAddressFormConfig.firstName,
        rules: [
          ...coreBillingAddressFormConfig.firstName.rules,
          generalValidationRules.maxLength(20),
        ],
      },
      lastName: {
        ...coreBillingAddressFormConfig.lastName,
        rules: [
          ...coreBillingAddressFormConfig.lastName.rules,
          generalValidationRules.maxLength(30),
        ],
      },
      company: {
        ...coreBillingAddressFormConfig.company,
        label: isAuthenticated ? 'Bedrijf' : 'Bedrijfsnaam / naam',
        rules: [
          isAuthenticated && customerType === CustomerTypes.company
            ? generalValidationRules.required
            : undefined,
          generalValidationRules.maxLength(50),
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      vatId: {
        ...coreBillingAddressFormConfig.vatId,
        rules: [
          ...coreBillingAddressFormConfig.vatId.rules,
          generalValidationRules.maxLength(15),
        ],
      },
      //currently not used
      fax: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.maxLength(50)],
      },
      //currently not used
      website: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.maxLength(250)],
      },
      email: {
        ...coreBillingAddressFormConfig.email,
        rules: [
          ...coreBillingAddressFormConfig.email.rules,
          generalValidationRules.maxLength(250),
        ],
      },
      phone: {
        ...coreBillingAddressFormConfig.phone,
        rules: [
          ...coreBillingAddressFormConfig.phone.rules,
          generalValidationRules.maxLength(50),
        ],
      },
      mobile: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.maxLength(50)],
      },
      city: {
        ...coreBillingAddressFormConfig.city,
        rules: [
          ...coreBillingAddressFormConfig.city.rules,
          generalValidationRules.maxLength(50),
        ],
      },
      //max length 5 for client achieved in overwriting validators validatePostalCode
      postalCode: {
        ...coreBillingAddressFormConfig.postalCode,
        rules: [...coreBillingAddressFormConfig.postalCode.rules],
      },
      //currently not used
      postBox: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.maxLength(10)],
      },
      street: {
        ...coreBillingAddressFormConfig.street,
        rules: [
          ...coreBillingAddressFormConfig.street.rules,
          generalValidationRules.maxLength(50),
        ],
      },
      customerNumber: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.maxLength(250)],
      },
    }
  },
  shippingAddressForm(customerType: CustomerTypes, isAuthenticated?: boolean) {
    const coreShippingAddressFormConfig = coreFormConfig.shippingAddressForm(
      customerType,
      isAuthenticated
    )
    return {
      ...coreShippingAddressFormConfig,
      costCenter: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.maxLength(50)],
      },
      // relates to OrderObject
      objectNumber: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.maxLength(50)],
      },
      notes: {
        validateTrigger: 'onBlur',
        rules: [generalValidationRules.maxLength(250)],
      },
      city: {
        ...coreShippingAddressFormConfig.city,
        rules: [
          ...coreShippingAddressFormConfig.city.rules,
          generalValidationRules.maxLength(50),
        ],
      },
      //max length 5 for client achieved in overwriting validators validatePostalCode
      postalCode: {
        ...coreShippingAddressFormConfig.postalCode,
        rules: [...coreShippingAddressFormConfig.postalCode.rules],
      },
      // contains street+ streetNumber
      street: {
        ...coreShippingAddressFormConfig.street,
        rules: [
          ...coreShippingAddressFormConfig.street.rules,
          generalValidationRules.maxLength(50),
        ],
      },
      //relates to wws order address name
      company: {
        ...coreShippingAddressFormConfig.company,
        label: isAuthenticated ? 'Bedrijf' : 'Bedrijfsnaam / naam',
        rules: [
          isAuthenticated && customerType === CustomerTypes.company
            ? generalValidationRules.required
            : undefined,
          generalValidationRules.maxLength(50),
        ].filter((rule) => rule !== undefined) as Rule[],
      },
      // Splitting Name Extra MaxLength into 20 for firstName and 30 for lastName
      firstName: {
        ...coreShippingAddressFormConfig.firstName,
        rules: [
          ...coreShippingAddressFormConfig.firstName.rules,
          generalValidationRules.maxLength(20),
        ],
      },
      lastName: {
        ...coreShippingAddressFormConfig.lastName,
        rules: [
          ...coreShippingAddressFormConfig.lastName.rules,
          generalValidationRules.maxLength(30),
        ],
      },
      // relates to wws Order user phone
      phone: {
        ...coreShippingAddressFormConfig.phone,
        rules: [
          ...coreShippingAddressFormConfig.phone.rules,
          generalValidationRules.maxLength(50),
        ],
      },
    }
  },
  registerForm: {
    ...coreFormConfig.registerForm,
    gender: {},

    firstName: {
      ...coreFormConfig.registerForm.firstName,
      rules: [
        ...coreFormConfig.registerForm.firstName.rules,
        generalValidationRules.maxLength(20),
      ],
    },
    lastName: {
      ...coreFormConfig.registerForm.lastName,
      rules: [
        ...coreFormConfig.registerForm.lastName.rules,
        generalValidationRules.maxLength(30),
      ],
    },
    postalCode: {
      validateTrigger: 'onBlur',
      rules: [
        generalValidationRules.required,
        { validator: validators.validatePostalCode },
      ],
    },
    phone: {
      validateTrigger: 'onBlur',
      rules: [
        generalValidationRules.required,
        generalValidationRules.maxLength(50),
      ],
    },
    customerNumber: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.maxLength(250)],
    },
    email: {
      ...coreFormConfig.registerForm.email,
      rules: [
        ...coreFormConfig.registerForm.email.rules,
        generalValidationRules.maxLength(250),
      ],
    },
    company: {
      ...coreFormConfig.registerForm.company,
      rules: [
        ...coreFormConfig.registerForm.company.rules,
        generalValidationRules.maxLength(100),
      ],
    },
    chamberOfCommerce: {
      rules: [
        generalValidationRules.maxLength(8),
        generalValidationRules.required,
        {
          pattern: /^[0-9]{8}$/,
          get message() {
            return i18n.t('form.register.patternHint')
          },
        },
      ],
    },
  },
  requiredFieldsModal: {
    gender: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
    firstName: {
      validateTrigger: 'onBlur',
      rules: [
        generalValidationRules.required,
        generalValidationRules.maxLength(20),
      ],
    },
    lastName: {
      validateTrigger: 'onBlur',
      rules: [
        generalValidationRules.required,
        generalValidationRules.maxLength(30),
      ],
    },
    phone: {
      validateTrigger: 'onBlur',
      rules: [
        generalValidationRules.required,
        generalValidationRules.maxLength(50),
      ],
    },
  },
  editUserForm: {
    ...coreFormConfig.editUserForm,
    firstName: {
      ...coreFormConfig.editUserForm.firstName,
      rules: [
        ...coreFormConfig.editUserForm.firstName.rules,
        generalValidationRules.maxLength(20),
      ],
    },
    lastName: {
      ...coreFormConfig.editUserForm.lastName,
      rules: [
        ...coreFormConfig.editUserForm.lastName.rules,
        generalValidationRules.maxLength(30),
      ],
    },
    postalCode: {
      validateTrigger: 'onBlur',
      rules: [
        generalValidationRules.required,
        { validator: validators.validatePostalCode },
      ],
    },
    phone: {
      validateTrigger: 'onBlur',
      rules: [
        generalValidationRules.required,
        generalValidationRules.maxLength(50),
      ],
    },
    customerNumber: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.maxLength(250)],
    },
    email: {
      ...coreFormConfig.editUserForm.email,
      rules: [
        ...coreFormConfig.editUserForm.email.rules,
        generalValidationRules.maxLength(250),
      ],
    },
    company: {
      ...coreFormConfig.editUserForm.company,
      rules: [
        ...coreFormConfig.editUserForm.company.rules,
        generalValidationRules.maxLength(100),
      ],
    },
    channel: {
      validateTrigger: 'onBlur',
      rules: [generalValidationRules.required],
    },
  },
  checkoutAdditionalInfo: {
    ...coreFormConfig.checkoutAdditionalInfo,
    customerPurchaseOrderNumber: {
      ...coreFormConfig.checkoutAdditionalInfo.customerPurchaseOrderNumber,
      rules: [
        ...coreFormConfig.checkoutAdditionalInfo.customerPurchaseOrderNumber
          .rules,
        generalValidationRules.maxLength(30),
      ],
    },
  },
}

export default formConfig
