import * as React from 'react'
import {
  LYSCol,
  LYSDivider,
  LYSIcon,
  LYSRow,
  LYSTypography,
} from '@core/components/Primitives'
import config from '../../config/config'
import { contactConfig } from '@core/config/contactConfig'
import routes from '@core/config/routes'
import useCategoryNavigation from '@core/hooks/categories/useCategoryNavigation'
import { useTranslation } from '@core/i18n/i18n'
import LYSStaticImage from '@core/components/Primitives/LYSStaticImage'
import FooterLinkCollection from '@core/components/Footer/FooterItemCollection'
import FooterLink from '@core/components/Footer/FooterLink'
import style from '@core/components/Footer/index.module.less'

const Footer: React.FC = () => {
  const { t } = useTranslation()
  const { root } = useCategoryNavigation()
  const mainCategories = root?.children

  return (
    <div className={style.footer} data-testid={'footer'}>
      <LYSRow>
        <LYSCol xs={24} sm={12} lg={5}>
          <LYSTypography.Paragraph
            visualAppearance={'h4'}
            inheritColor
            className={style.footerHeadlines}
          >
            {t('footer.customerServiceText')}
          </LYSTypography.Paragraph>
          <FooterLinkCollection flexDirection={'column'}>
            <FooterLink
              {...routes.faq}
              textLabel={t('footer.generalQuestionsText')}
            />
            <FooterLink
              {...routes.delivery}
              textLabel={t('footer.deliveryText')}
            />
            <FooterLink
              {...routes.returnPolicy}
              textLabel={t('footer.returnText')}
            />
            <FooterLink
              href={`/page/over-ons`}
              as={`/page/over-ons`}
              textLabel={t('footer.aboutUs')}
            />
            <FooterLink
              href={`/page/blog`}
              as={`/page/blog`}
              textLabel={t('footer.blog')}
            />
          </FooterLinkCollection>

          <LYSTypography.Paragraph
            visualAppearance={'h4'}
            inheritColor
            className={style.footerHeadlines}
          >
            {t('footer.myAccountText')}
          </LYSTypography.Paragraph>
          <FooterLinkCollection flexDirection={'column'}>
            <FooterLink {...routes.login()} textLabel={t('footer.loginText')} />
            <FooterLink
              {...routes.orderOverview()}
              textLabel={t('footer.ordersText')}
            />
          </FooterLinkCollection>
        </LYSCol>
        <LYSCol xs={24} sm={12} lg={7}>
          <LYSTypography.Paragraph
            visualAppearance={'h4'}
            inheritColor
            className={style.footerHeadlines}
          >
            {t('footer.categoriesText')}
          </LYSTypography.Paragraph>
          {mainCategories && (
            <FooterLinkCollection flexDirection={'column'}>
              <>
                {mainCategories.map((category) => {
                  const taxonomyRoute = routes.taxonomy(category)
                  return (
                    <FooterLink
                      {...taxonomyRoute}
                      textLabel={category.name}
                      key={category.id}
                    />
                  )
                })}
              </>
            </FooterLinkCollection>
          )}

          <LYSTypography.Paragraph
            visualAppearance={'h4'}
            inheritColor
            className={style.footerHeadlines}
          >
            {t('footer.contactText')}
          </LYSTypography.Paragraph>
          <FooterLinkCollection flexDirection={'column'}>
            <LYSTypography.Text strong={true}>
              {t('footer.contactEmailLabel')}
            </LYSTypography.Text>
            <LYSTypography.Text>
              <a href={`mailto:${contactConfig.email}`}>
                {contactConfig.email}
              </a>
            </LYSTypography.Text>
            <LYSTypography.Text strong={true}>
              {t('footer.contactPhoneLabel')}
            </LYSTypography.Text>
            <LYSTypography.Text>
              <a href={contactConfig.phoneLink}>{contactConfig.telephone}</a>
            </LYSTypography.Text>
          </FooterLinkCollection>
        </LYSCol>
        <LYSCol xs={24} lg={12} className={style.footerNav}>
          <LYSTypography.Paragraph
            visualAppearance={'h4'}
            inheritColor
            className={style.footerHeadlines}
          >
            {t('footer.paymentMethodsText')}
          </LYSTypography.Paragraph>

          <FooterLinkCollection flexDirection={'row'}>
            <LYSStaticImage
              src={config.assets.paymentMethods.rabo_ideal}
              width={56}
              height={40}
              alt={t`checkout.payment.methods.rabo_ideal`}
            />

            <LYSStaticImage
              src={config.assets.paymentMethods.payone_paypal}
              width={56}
              height={40}
              alt={t`checkout.payment.methods.payone_paypal`}
            />
          </FooterLinkCollection>

          <LYSDivider className={style.divider} />

          <LYSTypography.Paragraph
            visualAppearance={'h4'}
            inheritColor
            className={style.footerHeadlines}
          >
            {t('footer.deliveryPatnerText')}
          </LYSTypography.Paragraph>
          <FooterLinkCollection
            flexDirection={'row'}
            className={style.footerLogoCollection}
          >
            <LYSStaticImage
              src={config.assets.shippingMethods.dhl}
              width={56}
              height={40}
              alt={'DHL'}
            />
            <LYSStaticImage
              src={config.assets.shippingMethods.default}
              width={56}
              height={40}
              alt={'Shipping'}
              className={style.shippingLogo}
            />
          </FooterLinkCollection>
          <LYSDivider className={style.divider} />

          {contactConfig.social?.length > 0 ? (
            <>
              <LYSTypography.Paragraph
                visualAppearance={'h4'}
                inheritColor
                className={style.footerHeadlines}
              >
                {t('footer.socialMedia')}
              </LYSTypography.Paragraph>
              <FooterLinkCollection
                flexDirection={'row'}
                className={style.socialMedia}
              >
                {contactConfig.social.map((socialItem) => {
                  return (
                    <FooterLink
                      key={socialItem.url}
                      href={socialItem.url}
                      newTab={true}
                    >
                      <LYSIcon component={socialItem.icon} />
                    </FooterLink>
                  )
                })}
              </FooterLinkCollection>
            </>
          ) : null}
        </LYSCol>
      </LYSRow>
    </div>
  )
}

export default Footer
