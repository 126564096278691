import { Environment } from '@core/utils/Environment'
import coreConfig from '@original/config/config'

const config = {
  ...coreConfig,
  storeName: 'Hazet E-Shop',
  baseUrl: Environment.default.get('SHOP_BASE_URL') || 'https://shop.igefa.nl',
  assets: {
    ...coreConfig.assets,
    paymentMethods: {
      ...coreConfig.assets.paymentMethods,
      rabo_ideal: '/static/assets/paymentMethods/ideal.png',
      rabo_master_card: '/static/assets/paymentMethods/mastercard.png',
      rabo_v_pay: '/static/assets/paymentMethods/v-pay.png',
      rabo_paypal: '/static/assets/paymentMethods/paypal.png',
      rabo_maestro: '/static/assets/paymentMethods/maestro.png',
      rabo_visa: '/static/assets/paymentMethods/visa.png',
    },
    shippingMethods: {
      ...coreConfig.assets.shippingMethods,
      default: '/static/assets/igefa/truck_delivery.svg',
    },
    storeLogo: '/static/assets/hazet/hazet-logo.png',
    storeLogoMobile: '/static/assets/hazet/hazet-logo.png',
    favicon: '/static/assets/hazet/favicon.png',
    benefits: {
      deliveryTruck: '/static/assets/hazet/benefits/delivery-truck.svg',
      support: '/static/assets/hazet/benefits/support.svg',
      checklist: '/static/assets/hazet/benefits/checklist.svg',
    },
  },

  features: {
    ...coreConfig.features,
    billingInformationEditable: false,
    disableAddToCartWhenOutOfStock: false,
    searchTypeSwitchEnabled: false,
    pictureZoom: {
      ...coreConfig.features.pictureZoom,
      enabled: false,
    },
    fullSizeImageGallery: {
      ...coreConfig.features.fullSizeImageGallery,
      enabled: false,
    },
    suppliedCountries: ['NL'], // Array of alpha-2 country codes
    scrollToTopButton: { enabled: false, withText: false },
    newProductsFeature: false,
    vatIdPrefix: 'NL',
    /**
     * Regex for the default vat id validator
     */
    vatIdValidationRegex: /^(NL)?[0-9]{9}B[0-9]{2}$/,
    exportProductListToCSV: false,
    quickSearchRecommendations: { enabled: false, limit: 5 },
    addToCartInSearchEnabled: false,
    header: {
      productListDropdown: false,
      userMenuWithText: false,
      buttonType: 'primary',
    },
    categoryNavigation: {
      visibleCategoryNavItems: false,
      overflowCategoryNavItems: false,
      hiddenCategoryNavItems: false,
    },
    showLastVisitedProductsOnPDP: false,
    filters: {
      ...coreConfig.features.filters,
      showOnlyPurchasedProducts: false,
      showOnlyRecentlyPurchasedProducts: false, // products bought in the last 6 months
      showOnProductListFilter: false,
    },
    searchResultRecommendations: false, // Display recommendations on search result page if no search results exist
    quickSearchHistory: { enabled: false, limit: 5 },
  },

  prismic: {
    name: 'hazet',
    landingPageSlug: 'landing-page',
  },

  cookiebot: {
    cbid: 'e8a2e10b-a81c-46d1-888e-9cfca4907f56',
  },
}

export default config
