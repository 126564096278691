import assign from 'lodash/assign'
import { IAnonymousUser, IUser } from '@core/api/User/types'
import {
  showErrorNotification,
  showNotification,
} from '@core/components/Notification'
import routes from '@core/config/routes'
import {
  checkForUpdatedCartEvents,
  createCart,
  deleteCart,
} from '@core/store/cart/actions'
import {
  getBillingAddresses,
  getShippingAddresses,
  setBillingInformation,
  setShippingAddresses,
} from '@core/store/customer/actions'
import {
  ILoginCredentials,
  IAgentLoginCredentials,
} from '@core/store/customer/types'
import {
  fetchProductLists,
  setProductLists,
} from '@core/store/productList/actions'
import thunk from '@core/store/thunk'
import { logging } from '@core/utils/logging'
import { sanitizeModel } from '@core/utils/models/sanitizeModel'
import { i18n } from '../../i18n/i18n'
import { fetchPriceRequests } from '../priceRequests/actions'
import { getUser } from './selectors'
import { AUTH_TOKEN, CART_ID } from '@core/utils/cookies'

export enum ActionTypes {
  SET_USER = 'SET_USER',
  SET_ANONYMOUS_USER = 'SET_ANONYMOUS_USER',
}

export interface ISetUser {
  type: ActionTypes.SET_USER
  payload: IUser
}

export interface ISetAnonymousUser {
  type: ActionTypes.SET_ANONYMOUS_USER
  payload: IAnonymousUser
}

export const fetchUser = () =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      const user = await dependencies.api.user.getUserDetails()
      if ('id' in user && user.id !== null) {
        dispatch(setUser(user))
      } else {
        dispatch(setAnonymousUser(user))
      }
    } catch (error) {
      if (error.response?.status === 403) {
        showNotification(i18n.t('user.accountDisabled'), 'warning')
        dependencies.router.navigate('/auth/login')
      }
    }
  })

export const setUser = (user: IUser): ISetUser => ({
  type: ActionTypes.SET_USER,
  payload: user,
})

export const setAnonymousUser = (user: IAnonymousUser): ISetAnonymousUser => ({
  type: ActionTypes.SET_ANONYMOUS_USER,
  payload: user,
})

export const fetchUserRelatedData = () =>
  thunk(async (dispatch) => {
    return Promise.all([
      dispatch(getShippingAddresses()),
      dispatch(getBillingAddresses()),
      dispatch(fetchProductLists()),
      dispatch(fetchPriceRequests()),
      dispatch(checkForUpdatedCartEvents()),
    ])
  })

export const clearUserRelatedData = () =>
  thunk((dispatch) => {
    dispatch(setShippingAddresses([]))
    dispatch(setBillingInformation([]))
    dispatch(setProductLists([]))
  })

export const login = (credentials: ILoginCredentials, returnUrl?: string) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    const { email, password } = credentials
    await dependencies.api.user.login({ username: email, password })

    // Force a page reload here to ensure all user / channel related data is refreshed
    dependencies.router.navigate(returnUrl || '/')
  })

export const agentLogin = (
  credentials: IAgentLoginCredentials,
  returnUrl?: string
) =>
  thunk(async (dispatch, _getState, dependencies) => {
    const { token } = credentials

    const cartId = dependencies.cookies.get(CART_ID)
    if (cartId) {
      await dispatch(deleteCart())
    }

    // if we have an existing user and cart, we remove them
    const authToken = dependencies.cookies.get(AUTH_TOKEN)
    if (authToken) {
      await dependencies.api.user.logout()
    }

    await dispatch(createCart())

    // setting the auth token to the one provided in the url by the admin
    await dependencies.api.user.agentLogin({ token })

    // Force a page reload here to ensure all user / channel related data is refreshed
    dependencies.router.navigate(returnUrl || '/')
  })

export const logout = () =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      dependencies.api.user.logout()
      // Switch to anon. user
      await dispatch(fetchUser())
      // Anon. user must not have access to customer cart, so create an new one
      await dispatch(createCart())
      dependencies.router.navigate('/')
    } catch (error) {
      showErrorNotification(error, i18n.t('user.logout.errorNotification'))
    }
  })

export const changePassword = (currentPassword: string, newPassword: string) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    await dependencies.api.user.changePassword(currentPassword, newPassword)

    showNotification(i18n.t('form.password.change.changeSuccess'), 'success')

    await dependencies.router.pushAndScrollTop('/me')
  })

export const resetPassword = (token: string, password: string) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    await dependencies.api.user.resetPassword(token, password)

    showNotification(i18n.t('form.password.reset.resetSuccess'), 'success')

    await dependencies.router.pushAndScrollTop(routes.login().href)
  })

export const validatePasswordResetToken = (token?: string) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    if (!token) {
      showErrorNotification(
        i18n.t('form.password.reset.resetTokenInvalid'),
        'error'
      )
      await dependencies.router.pushAndScrollTop(routes.login().href)
      return
    }

    const response = await dependencies.api.user.validatePasswordResetToken(
      token
    )
    if (response.status === 422) {
      showErrorNotification(
        i18n.t('form.password.reset.resetTokenInvalid'),
        'error'
      )
      await dependencies.router.pushAndScrollTop(routes.login().href)
    }
  })

export const requestPasswordReset = (email: string) =>
  thunk(async (_dispatch, _getState, dependencies) => {
    await dependencies.api.user.requestPasswordReset(email)
    await dependencies.router.pushAndScrollTop('/auth/forgot-password/success')
  })

export const updateUser = (user: Partial<IUser>) =>
  thunk(async (dispatch, getState, dependencies) => {
    try {
      const state = getState()
      const storeUser = getUser(state).user

      const mergedUser = sanitizeModel(assign({}, storeUser || {}, user))

      /**
       * merged one is a full IUser again, but changed the beheviour to not accidantly passing
       * partials and overwriting present user data
       */
      await dependencies.api.user.updateUser(mergedUser as IUser)

      await dispatch(fetchUser())
    } catch (error) {
      logging.error(error, { user })
      showErrorNotification(error, 'me.edit.editErrorNotification')
    }
  })

export type Action = ISetAnonymousUser | ISetUser
