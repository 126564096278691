import Link from 'next/link'
import { RightOutlined } from '@ant-design/icons'
import { LYSButton, LYSIcon } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'

const CartMenuButtons: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={style.wrap}>
      <div className={style.button}>
        <Link href="/cart" prefetch={false}>
          <LYSButton
            type="primary"
            block={true}
            className={style.shoppingButton}
            data-testid="edit-cart"
          >
            {t('cart.menu.editCartText')}

            <LYSIcon component={RightOutlined} />
          </LYSButton>
        </Link>
      </div>
    </div>
  )
}

export default CartMenuButtons
