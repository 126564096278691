import React from 'react'
import cn from 'classnames'
import { SearchOutlined } from '@ant-design/icons'
import AutoSuggest from '@core/components/AutoSuggest'
import { LYSButton } from '@core/components/Primitives'
import { usePageContext } from '@core/utils/pages/PageContext'
import style from './index.module.less'
import { useAppBarContext } from '../Context/appBarContext'

export interface Props {
  toggleButtonClassName?: string
  autoSuggestClassName?: string
  showTaxonomies?: boolean
}
const AutoSuggestResponsive: React.FC<Props> = ({
  toggleButtonClassName,
  autoSuggestClassName,
  showTaxonomies = false,
}) => {
  const { setAutoSuggestOpen, autoSuggestOpen } = useAppBarContext()

  const handleShow = () => {
    if (setAutoSuggestOpen) setAutoSuggestOpen(true)
  }
  const handleClose = () => {
    if (setAutoSuggestOpen) setAutoSuggestOpen(false)
  }

  const {
    props: { isMobile },
  } = usePageContext()

  return isMobile ? (
    <>
      <LYSButton
        type="link"
        ghost={true}
        icon={<SearchOutlined rev={undefined} />}
        className={toggleButtonClassName}
        onClick={handleShow}
      />
      {autoSuggestOpen && (
        <div className={style.overlay}>
          <AutoSuggest
            autoFocus={true}
            className={cn(style.autoSuggest, autoSuggestClassName)}
            staticCloseButton={true}
            onClose={handleClose}
            showTaxonomies={showTaxonomies}
          />
        </div>
      )}
    </>
  ) : (
    <AutoSuggest
      className={autoSuggestClassName}
      showTaxonomies={showTaxonomies}
    />
  )
}

export default AutoSuggestResponsive
