import React, { ReactNode } from 'react'
import { PercentageOutlined } from '@ant-design/icons'
import { MenuBar } from '@core/components/shared/menu/MenuBar'
import { MenuBehaviour } from '@core/components/shared/menu/MenuBehaviour'
import { MenuDropdown } from '@core/components/shared/menu/MenuDropdown'
import { MenuItem, MenuItemProps } from '@core/components/shared/menu/MenuItem'
import appBarConfig from '../../../../config/appBarConfig'
import style from './index.module.less'
import { AssortmentMenu } from './menus/AssortmentMenu'
import { LYSContainer } from '@core/components/Primitives'

const HazetShopNavItem: React.FC<
  MenuItemProps & {
    title: string
    icon?: ReactNode
    iconUrl?: string
  }
> = ({ title, icon, iconUrl, ...props }) => {
  return (
    <MenuItem
      {...props}
      icon={icon}
      className={style.navItem}
      activeClassName={style.active}
      aria-haspopup={'true'}
      activatable
    >
      {iconUrl ? (
        <img className={style.imageIcon} src={iconUrl} alt={''} />
      ) : null}
      <span>{title}</span>
    </MenuItem>
  )
}

export const HazetShopNavMenu: React.FC = () => {
  return (
    <MenuBehaviour
      menuId={'desktopNavMenu'}
      items={[appBarConfig.assortment]}
      keyboardNavigationDirection={'horizontal'}
    >
      <MenuBar className={style.menuBar} anchor>
        <LYSContainer className={style.container}>
          <div key={appBarConfig.assortment.id}>
            <MenuBehaviour.Trigger item={appBarConfig.assortment}>
              <HazetShopNavItem
                title={appBarConfig.assortment.title}
                icon={appBarConfig.assortment.icon}
              />
            </MenuBehaviour.Trigger>
            <MenuBehaviour.Content item={appBarConfig.assortment}>
              <MenuDropdown key={appBarConfig.assortment.id} anchored>
                <AssortmentMenu />
              </MenuDropdown>
            </MenuBehaviour.Content>
          </div>
          {appBarConfig.brands.enabled ? (
            <a href={appBarConfig.brands.href}>
              <HazetShopNavItem
                title={appBarConfig.brands.title}
                icon={appBarConfig.brands.icon}
                iconUrl={appBarConfig.brands.iconUrl}
              />
            </a>
          ) : null}
          {appBarConfig.huismerken.enabled ? (
            <a href={appBarConfig.huismerken.href}>
              <HazetShopNavItem
                title={appBarConfig.huismerken.title}
                icon={appBarConfig.huismerken.icon}
                iconUrl={appBarConfig.huismerken.iconUrl}
              />
            </a>
          ) : null}
          {appBarConfig.sustainability.enabled ? (
            <a href={appBarConfig.sustainability.href}>
              <HazetShopNavItem
                title={appBarConfig.sustainability.title}
                icon={appBarConfig.sustainability.icon}
                iconUrl={appBarConfig.sustainability.iconUrl}
              />
            </a>
          ) : null}
          {appBarConfig.outlet.enabled ? (
            <a href={appBarConfig.outlet.href}>
              <HazetShopNavItem
                title={appBarConfig.outlet.title}
                icon={
                  <PercentageOutlined
                    className={style.percentageIcon}
                    rev={undefined}
                  />
                }
              />
            </a>
          ) : null}
          {appBarConfig.rentalmachines.enabled ? (
            <a href={appBarConfig.rentalmachines.href}>
              <HazetShopNavItem
                title={appBarConfig.rentalmachines.title}
                icon={appBarConfig.rentalmachines.icon}
                iconUrl={appBarConfig.rentalmachines.iconUrl}
              />
            </a>
          ) : null}
        </LYSContainer>
      </MenuBar>
    </MenuBehaviour>
  )
}
