import React from 'react'
import cn from 'classnames'
import Link from 'next/link'
import { HeartOutlined } from '@ant-design/icons'
import { QuickCart } from '@core/components/Cart/QuickCart'
import { LYSButton, LYSContainer, LYSSpacer } from '@core/components/Primitives'
import AutoSuggestResponsive from '@core/components/header/AppBar/AutoSuggestResponsive/index'
import StoreLogo from '@core/components/header/AppBar/StoreLogo'
import { UserMenu } from '@core/components/header/AppBar/UserMenu'
import MobileCategoryNavMenu from '@core/components/header/Navigation/MobileCategoryNavMenu'
import useCurrentUser from '@core/hooks/user/useCurrentUser'
import { usePageContext } from '@core/utils/pages/PageContext'
import customStyle from './custom.module.less'
import style from './index.module.less'
import { useAppBarContext } from '@core/components/header/AppBar/Context/appBarContext'
import { Button } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

const AppBar: React.FC = () => {
  const {
    props: { isMobile, isTablet },
  } = usePageContext()
  const { categoryMenuOpen, setCategoryMenuOpen } = useAppBarContext()
  const { isAuthenticated } = useCurrentUser()
  const isTouchDevice = isMobile || isTablet
  const buttonStyles = cn(style.appbarButton, customStyle.appbarButton)

  return (
    <div className={cn(style.appbar, customStyle.appBar)}>
      <LYSContainer className={customStyle.container}>
        {isTouchDevice ? (
          <>
            <MobileCategoryNavMenu />
            <Button
              icon={<MenuOutlined rev={undefined} />}
              size={'large'}
              type={'primary'}
              onClick={() => {
                if (setCategoryMenuOpen) setCategoryMenuOpen(!categoryMenuOpen)
              }}
              ghost
            />
          </>
        ) : null}
        <StoreLogo className={cn(style.storeLogo, customStyle.storeLogo)} />
        <LYSSpacer className={style.spacer} />
        <AutoSuggestResponsive
          autoSuggestClassName={cn(style.search, customStyle.autoSuggest)}
          toggleButtonClassName={buttonStyles}
          showTaxonomies={true}
        />
        {isAuthenticated && (
          <Link href={'/me/product-list'}>
            <LYSButton
              type={'primary'}
              ghost={true}
              size={'large'}
              icon={<HeartOutlined rev={undefined} />}
              className={buttonStyles}
            />
          </Link>
        )}
        <QuickCart buttonClassName={buttonStyles} />
        <UserMenu buttonClassName={buttonStyles} />
      </LYSContainer>
    </div>
  )
}
export default AppBar
