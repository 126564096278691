import queryString from 'querystring'
import actionCreatorFactory from 'typescript-fsa'
import {
  IAddCartItems,
  ICart,
  ICartItem,
  IOrderBillingInformation,
  IOrderShippingAddress,
  IPaymentInformation,
  IPaymentInformationPayload,
  IPaymentMethod,
  IShippingInformation,
  IShippingMethod,
  isItemInCart,
} from '@core/api/Cart/types'
import { CustomerTypes } from '@core/api/Channel/types'
import { ICheckout } from '@core/api/Checkout/types'
import { IBillingInformation, IShippingAddress } from '@core/api/Customer/types'
import { INewsletterSubscription } from '@core/api/NewsletterSubscription/types'
import {
  ICreateProductList,
  IProductListItem,
  IProductListItems,
  IProductListItemVariantInformation,
} from '@core/api/ProductLists/types'
import {
  IProductSearch,
  IVariant,
  IVariantSearch,
} from '@core/api/Products/types'
import { Unsaved } from '@core/api/types'
import {
  showErrorNotification,
  showNotification,
} from '@core/components/Notification'
import { LYSModal } from '@core/components/Primitives'
import config from '@core/config/config'
import i18nConfig from '@core/config/i18n'
import routes from '@core/config/routes'
import {
  getAdditionalCheckoutInfo,
  getUserCart,
} from '@core/store/cart/selectors'
import { ICheckoutConsent } from '@core/store/cart/types'
import {
  createBillingInformation,
  createShippingAddress,
  updateShippingAddress,
} from '@core/store/customer/actions'
import { ICustomerInformation } from '@core/store/customer/types'
import { subscribeNewsletter } from '@core/store/newsletter/actions'
import thunk from '@core/store/thunk'
import { getAuthenticatedUser } from '@core/store/user/selectors'
import { AUTH_REFRESH_TOKEN, AUTH_TOKEN, CART_ID } from '@core/utils/cookies'
import { logging } from '@core/utils/logging'
import { getHasCartItemEvents } from '@core/utils/models/cart'
import getCartEventsHash from '@core/utils/models/getCartEventsHash'
import { sanitizeModel } from '@core/utils/models/sanitizeModel'
import { externalRedirect } from '@core/utils/routing/router'
import {
  getQueryParamsString,
  getSingleQueryParamOrUndefined,
} from '@core/utils/url'
import { i18n } from '../../i18n/i18n'
import { ModalType, showModal } from '../modals/actions'
import { createProductList } from '../productList/actions'
import { IPriceRequestVariant } from '@core/api/PriceRequests/types'

export enum ActionTypes {
  SET_CART = 'CART/SET_CART',
  TOGGLE_CART_DRAWER = 'CART/TOGGLE_CART_DRAWER',
  CLOSE_CART_DRAWER = 'CART/CLOSE_CART_DRAWER',
  CART_UPDATE_PENDING = 'CART/CART_UPDATE_PENDING,',
}

export interface ISetCartAction {
  type: ActionTypes.SET_CART
  payload: ICart
}

export interface IToggleCartDrawerAction {
  type: ActionTypes.TOGGLE_CART_DRAWER
}

export interface ICloseCartDrawerAction {
  type: ActionTypes.CLOSE_CART_DRAWER
}

export interface ICartUpdatePendingAction {
  type: ActionTypes.CART_UPDATE_PENDING
  payload: boolean
}

export type Action =
  | ISetCartAction
  | IToggleCartDrawerAction
  | ICloseCartDrawerAction
  | ICartUpdatePendingAction

const actionCreator = actionCreatorFactory('Cart')

export const cartProcessing = {
  started: actionCreator<any>('PROCESSING_STARTED'),
  done: actionCreator<any>('PROCESSING_DONE'),
  failed: actionCreator<any>('PROCESSING_FAILED'),
}

export const toggleCartDrawer = () => ({
  type: ActionTypes.TOGGLE_CART_DRAWER,
})

export function setCartUpdatePending(payload: boolean) {
  return {
    type: ActionTypes.CART_UPDATE_PENDING,
    payload,
  }
}

export const closeCartDrawer = () => ({
  type: ActionTypes.CLOSE_CART_DRAWER,
})

export const getOrCreateCart = () =>
  thunk(async (dispatch, getState) => {
    // Try to load existing cart
    await dispatch(refreshCart())

    const { cart: existingCart } = getState().cart

    if (existingCart) return

    // Create new cart if there is no existing cart or we could not load it
    await dispatch(createCart())
  })

export const createCart = () =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      const cart = await dependencies.api.cart.createCart({
        currency: i18nConfig.currencyShort,
      })
      dispatch(setCart(cart))
      dependencies.cookies.set(CART_ID, cart.id)

      // Merge additional carts
      await dispatch(mergeCarts())
    } catch (error) {
      if (error.response?.status === 403) {
        // API throws an 403 on cart if the user has an invalid status on agent-login
        // manually to prevent dependency cycle (user.actions)
        dependencies.cookies.remove(AUTH_TOKEN)
        dependencies.cookies.remove(AUTH_REFRESH_TOKEN)
        dependencies.router.replace(routes.login().href)
      }
      logging.error(error)
    }
  })

export const deleteCart = () =>
  thunk(async (_, _getState, dependencies) => {
    try {
      const cartId = dependencies.cookies.get(CART_ID)
      if (!cartId) return
      await dependencies.api.cart.deleteCart(cartId)
      dependencies.cookies.remove(CART_ID)
    } catch (error) {
      logging.error(error)
    }
  })

export const deleteAndRecreateCart = () =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      const cartId = dependencies.cookies.get(CART_ID)
      if (!cartId) return
      await dependencies.api.cart.deleteCart(cartId)
      dependencies.cookies.remove(CART_ID)
      await dispatch(createCart())
    } catch (error) {
      logging.error(error)
    }
  })

export const refreshCart = () =>
  thunk(async (dispatch, _getState, dependencies) => {
    try {
      const cartId = dependencies.cookies.get(CART_ID)
      if (!cartId) return
      const cart = await dependencies.api.cart.fetchCart(cartId)

      dispatch(setCart(cart))
      // API might redirect to a different cart after login
      // so always set the returned cart id in local storage
      dependencies.cookies.set(CART_ID, cart.id)
      // Merge additional carts
      await dispatch(mergeCarts())
    } catch (error) {
      logging.error(error)
    }
  })

export const mergeCarts = () =>
  thunk(async (dispatch, getState, dependencies) => {
    try {
      const state = getState()
      let cart = getUserCart(state)

      if (cart?.additionalCartIds?.length) {
        const cartId = dependencies.cookies.get(CART_ID)

        for (const cartToMerge of cart.additionalCartIds) {
          try {
            cart = await dependencies.api.cart.mergeCart(cartId!, cartToMerge)
          } catch (e) {
            // better merge next than sorry
          }
        }

        dispatch(setCart(cart))
      }
    } catch (error) {
      logging.error(error)
    }
  })

export function setCart(payload: ICart): ISetCartAction {
  return {
    type: ActionTypes.SET_CART,
    payload,
  }
}

const enrichClientFields = () => {
  const partnerName = getPartnerNameOrUndefined()

  return partnerName
    ? {
        clientFields: {
          affiliateInformation: {
            partnerName: partnerName,
          },
        },
      }
    : {}
}

const getPartnerNameOrUndefined = (): string | undefined => {
  const params = queryString.parse(getQueryParamsString(window.location.search))
  const utmSource = getSingleQueryParamOrUndefined(params, 'utm_source')
  return utmSource && isValidPartnerName(utmSource)
    ? config.commerceConnector.partnerNames[utmSource]
    : undefined
}

const isValidPartnerName = (partnerName: string): boolean => {
  return Object.keys(config.commerceConnector.partnerNames).includes(
    partnerName
  )
}

export const addToCart = (
  variant:
    | IVariantSearch
    | IProductListItemVariantInformation
    | IVariant
    | IPriceRequestVariant,
  productName: string,
  quantity: number
) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    dispatch(setCartUpdatePending(true))

    const clientFields = enrichClientFields()

    // Cancel if cart is not yet loaded
    if (!cart) return

    const cartIsEmpty = cart.items.length === 0

    try {
      // Always execute add to cart, backend will handle updating quantities for existing items correctly
      const cartItems = {
        items: [
          {
            ...clientFields,
            variantId: variant.id,
            quantity,
          },
        ],
      }

      const updatedCart = await dependencies.api.cart.addToCart(
        cart.id,
        cartItems
      )

      dispatch(setCart(updatedCart))

      if (cartIsEmpty) {
        dispatch(toggleCartDrawer())
      } else if (isItemInCart(cart, variant.id)) {
        showNotification(
          // remove Flag when ready https://lyskahq.atlassian.net/browse/LCP-70
          i18n.t('cart.addToCart.quantityUpdate', {
            productName: `${productName} - ${`${quantity} ${i18n.t('unit', {
              count: quantity,
            })}`}`,
          }),
          'success'
        )
      } else {
        showNotification(
          // remove flag when ready https://lyskahq.atlassian.net/browse/LCP-70
          i18n.t('cart.addToCart.successText', {
            productName: `${productName} - ${`${quantity} ${i18n.t('unit', {
              count: quantity,
            })}`}`,
          }),
          'success'
        )
      }
    } catch (error) {
      logging.error(error, { cart })
      showErrorNotification(error, 'cart.addToCart.error')
    } finally {
      dispatch(setCartUpdatePending(false))
    }
  })

export const checkForUpdatedCartEvents = () =>
  thunk(async (dispatch, getState) => {
    /**
     * make sure latest card is loaded. This is currently resulting in double fetch of cart on login due
     * to the forced refresh and the combined init action that calls getOrCreateCart.
     */
    await dispatch(getOrCreateCart())

    const { cart } = getState().cart

    const hasCartItemEvents = getHasCartItemEvents(cart)

    if (hasCartItemEvents) {
      dispatch(showModal(ModalType.CART_UPDATE))
    }
  })

export const batchAddToCart = (addCartItems: IAddCartItems) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    // Cancel if cart is not yet loaded
    if (!cart) return
    dispatch(setCartUpdatePending(true))
    const updatedCart = await dependencies.api.cart.addToCart(
      cart.id,
      addCartItems
    )
    dispatch(setCart(updatedCart))
    dispatch(setCartUpdatePending(false))
  })

export const saveCartAsProductList = (productList: ICreateProductList) =>
  thunk(async (dispatch, getState) => {
    const { cart } = getState().cart
    const productListItems: IProductListItems[] = []

    // Cancel if cart is not yet loaded
    if (!cart) return
    dispatch(setCartUpdatePending(true))

    cart.items.forEach((item) =>
      productListItems.push({
        quantity: item.quantity,
        variantId: item.product.variant.id,
      })
    )
    dispatch(createProductList(productList, { productListItems }))

    dispatch(setCartUpdatePending(false))
  })

export const updateQuantity = (cartItem: ICartItem, quantity: number) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    // Cancel if cart is not yet loaded
    if (!cart) return
    dispatch(setCartUpdatePending(true))

    // Validate quantity, should exclude nullable types and zero
    const variantId = cartItem.product.variant.id

    if (!quantity || quantity < 0) {
      throw new Error(`Invalid quantity "${quantity}" for variant ${variantId}`)
    }

    const updatedCart = await dependencies.api.cart.updateCartItemQuantity(
      cart.id,
      variantId,
      quantity
    )

    // Refresh cart
    dispatch(setCart(updatedCart))
    dispatch(setCartUpdatePending(false))
  })

export const removeFromCart = (cartItem: ICartItem) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    // Cancel if cart is not yet loaded
    if (!cart) return
    dispatch(setCartUpdatePending(true))

    try {
      const updatedCart = await dependencies.api.cart.removeItemFromCart(
        cart.id,
        cartItem.product.variant.id
      )

      // Refresh cart
      dispatch(setCart(updatedCart))
    } catch (error) {
      logging.error(error, { cart })
      showErrorNotification(error, 'cart.removeFromCart.error')
    } finally {
      dispatch(setCartUpdatePending(false))
    }
  })

export const removeMultipleFromCart = (cartItem: ICartItem[]) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    // Cancel if cart is not yet loaded
    if (!cart) return
    dispatch(setCartUpdatePending(true))

    try {
      await Promise.all(
        cartItem.map((item) =>
          dependencies.api.cart.removeItemFromCart(
            cart.id,
            item.product.variant.id
          )
        )
      )
      const updatedCart = await dependencies.api.cart.fetchCart(cart.id)

      // Refresh cart
      dispatch(setCart(updatedCart))
    } catch (error) {
      logging.error(error, { cart })
      showErrorNotification(error, 'cart.removeFromCart.error')
    } finally {
      dispatch(setCartUpdatePending(false))
    }
  })

export const deleteAllItemsFromCartAction = () =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    // Cancel if cart is not yet loaded
    if (!cart) return
    dispatch(setCartUpdatePending(true))

    try {
      const updatedCart = await dependencies.api.cart.deleteAllCartItems(
        cart.id
      )

      // Refresh cart
      dispatch(setCart(updatedCart))
    } catch (error) {
      logging.error(error, { cart })
      showErrorNotification(error, 'cart.removeFromCart.error')
    } finally {
      dispatch(setCartUpdatePending(false))
    }
  })

/**
 * Note: While writing this feature real replace was not provided. For an interaction that the user is used to
 * we take the normal add to cart (dispaly notification and so on) and remove the old item
 * before
 */
export const replaceInCart = ({
  previousItem,
  newItem,
}: {
  previousItem: ICartItem
  newItem: IProductSearch
}) =>
  thunk(async (dispatch) => {
    await dispatch(
      addToCart(newItem.mainVariant, newItem.name, previousItem.quantity)
    )
    await dispatch(removeFromCart(previousItem))
  })

export const createAndSetShippingAddress = (
  address: Unsaved<IShippingAddress>,
  setAsDefaultShippingAddress: boolean,
  disableNotification?: boolean
) =>
  thunk(async (dispatch) => {
    address = sanitizeModel(address)

    const savedShippingAddress = await dispatch(
      createShippingAddress(
        address,
        setAsDefaultShippingAddress,
        disableNotification
      )
    )
    await dispatch(setOrderShippingAddress(savedShippingAddress))
  })

export const updateAndSetShippingAddress = (
  address: IShippingAddress,
  setAsDefaultShippingAddress: boolean
) =>
  thunk(async (dispatch) => {
    address = sanitizeModel(address)

    await dispatch(updateShippingAddress(address, setAsDefaultShippingAddress))
  })

export const setOrderShippingAddress = (
  shippingAddress: IOrderShippingAddress
) =>
  thunk(async (dispatch, getState, dependencies) => {
    shippingAddress = sanitizeModel(shippingAddress)

    const { cart } = getState().cart

    // Cancel if cart is not yet loaded
    if (!cart) throw new Error('No Cart')

    try {
      const updatedCart = await dependencies.api.cart.setOrderShippingAddress(
        cart.id,
        shippingAddress
      )
      dispatch(setCart(updatedCart))
    } catch (error) {
      logging.error(error, { cart })

      showErrorNotification(error, 'cart.setOrderShippingAddress.error')

      throw error
    }
  })

export const createAndSetBillingInformation = (
  information: Unsaved<IBillingInformation>,
  customerType: CustomerTypes,
  disableNotification?: boolean
) =>
  thunk(async (dispatch) => {
    information = sanitizeModel(information)

    const savedBillingInformation = await dispatch(
      createBillingInformation(information, disableNotification)
    )
    await dispatch(
      setOrderBillingInformation(savedBillingInformation, customerType)
    )
  })

export const setOrderBillingInformation = (
  billingInformation: IOrderBillingInformation,
  customerType: CustomerTypes
) =>
  thunk(async (dispatch, getState, dependencies) => {
    billingInformation = sanitizeModel(billingInformation)

    const { cart } = getState().cart

    // Cancel if cart is not yet loaded
    if (!cart) return

    try {
      const updatedCart =
        await dependencies.api.cart.setOrderBillingInformation(
          cart.id,
          billingInformation,
          customerType
        )
      dispatch(setCart(updatedCart))
    } catch (error) {
      logging.error(error, { cart })

      showErrorNotification(error, 'cart.setOrderBillingInformation.error')

      throw error
    }
  })

export const setOrderCustomerInformation = (
  customerInformation: ICustomerInformation
) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    // Cancel if cart is not yet loaded
    if (!cart) return

    try {
      customerInformation = sanitizeModel(customerInformation)
      const updatedCart =
        await dependencies.api.cart.setOrderCustomerInformation(
          cart.id,
          customerInformation
        )
      dispatch(setCart(updatedCart))
    } catch (error) {
      logging.error(error, { cart })

      showErrorNotification(error, 'cart.setOrderCustomerInformation.error')

      throw error
    }
  })

export const setShippingInformation = (
  method: IShippingMethod,
  wishedDeliveryDate?: { from: string | null; to: string | null },
  sendDataToThirdParties?: boolean
) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    // Cancel if cart is not yet loaded
    if (!cart) return

    const shippingInformation: IShippingInformation = sanitizeModel({
      shippingMethodId: method.id,
      wishedDeliveryDate,
      sendDataToThirdParties,
      payload: {},
    })

    try {
      const updatedCart = await dependencies.api.cart.setShippingInformation(
        cart.id,
        shippingInformation
      )

      dispatch(setCart(updatedCart))
    } catch (error) {
      logging.error(error, { cart })

      showErrorNotification(
        error,
        'checkout.shipping.setShippingInformationFailed'
      )

      throw error
    }
  })

export const setPaymentInformation = (
  paymentMethod: IPaymentMethod,
  payload: IPaymentInformationPayload
) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    if (!cart) return

    const paymentInformation: IPaymentInformation = sanitizeModel({
      paymentMethodId: paymentMethod.id,
      payload,
    })
    const updatedCart = await dependencies.api.cart.setPaymentInformation(
      cart.id,
      paymentInformation
    )

    dispatch(setCart(updatedCart))
  })

export const checkout = (consent: ICheckoutConsent) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart
    const additionalCheckoutInfo = getAdditionalCheckoutInfo(getState())

    if (!cart) throw new Error('Cart is required for checkout')

    // Check for new cart events
    const currentCart = await dependencies.api.cart.fetchCart(cart.id)
    if (!currentCart) throw new Error('Error fetching current cart')

    const hasNewCartEvents =
      getCartEventsHash(cart) !== getCartEventsHash(currentCart)

    if (hasNewCartEvents) {
      LYSModal.info({
        title: i18n.t('cart.events.modalText'),
      })
      dispatch(setCart(currentCart))
      return
    }

    // Optional newsletter subscription
    if (consent.newsletterAccepted) {
      try {
        const user = getAuthenticatedUser(getState())
        const email = user?.email || cart.customerInformation?.email
        const source = config.defaultNewsletterSource
        if (email && source) {
          const sub: INewsletterSubscription = { email, source }
          await dispatch(subscribeNewsletter(sub, true))
        }
      } catch (e) {
        logging.error('Failed to subscribe to newsletter during checkout', e)
      }
    }

    // Checkout
    const checkoutRequest: ICheckout = {
      cartId: cart.id,
      clientFields: {
        customerPurchaseOrderNumber:
          additionalCheckoutInfo.customerPurchaseOrderNumber,
      },
    }
    try {
      await dispatch(
        setShippingInformation(
          cart.shippingMethod!,
          {
            from:
              cart.wishedDeliveryDate?.from ??
              cart.estimatedDeliveryDate ??
              null,
            to:
              cart.wishedDeliveryDate?.to ?? cart.estimatedDeliveryDate ?? null,
          },
          consent.sendDataThirdParties
        )
      )
      const checkoutResponse = await dependencies.api.checkout.checkout(
        checkoutRequest
      )
      const checkoutURL = checkoutResponse.paymentRedirectUrl

      externalRedirect(checkoutURL)
    } catch (error) {
      logging.error(error, { cart })

      showErrorNotification(error, 'checkout.checkoutRequestFailed')

      throw error
    }
  })

export const addProductListToCart = (productListId: string) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart
    if (!cart) return
    dispatch(setCartUpdatePending(true))

    try {
      await dependencies.api.productLists.addProductListToCart(
        productListId,
        cart.id
      )

      showNotification(
        i18n.t('addAllItemsToCart.successNotification'),
        'success'
      )

      await dispatch(refreshCart())
    } catch (error) {
      logging.error(error, { cart })

      showErrorNotification(error, 'addAllItemsToCart.errorNotification')
    } finally {
      dispatch(setCartUpdatePending(false))
    }
  })

export const addProductListItemsToCart = (
  selectedProductListItems: IProductListItem[]
) =>
  thunk(async (dispatch) => {
    try {
      const addCartItems = {
        items: selectedProductListItems.map((productListItem) => ({
          variantId: productListItem.product.variant.id,
          quantity: productListItem.quantity,
        })),
      }

      await dispatch(batchAddToCart(addCartItems))

      showNotification(i18n.t('wishlist.addToCart.successText'), 'success')
    } catch (error) {
      logging.error(error, {
        selectedProductListItems,
      })

      showErrorNotification(error, 'wishlist.addToCart.errorText')
    }
  })

export const addBundleItemsToCart = (selectedBundleItems: IProductSearch[]) =>
  thunk(async (dispatch, _getState, _dependencies) => {
    try {
      const addCartItems = {
        items: selectedBundleItems.map((bundleItem) => ({
          variantId: bundleItem.mainVariant.id,
          quantity: 1,
        })),
      }

      await dispatch(batchAddToCart(addCartItems))

      showNotification(
        i18n.t('productDetail.productBundle.addToCart.successText'),
        'success'
      )
    } catch (error) {
      logging.error(error, { selectedBundleItems })

      showErrorNotification(
        error,
        'productDetail.productBundle.addToCart.errorText'
      )
    }
  })

export const applyVoucherCode = (code: string) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    if (!cart) return

    const updatedCart = await dependencies.api.cart.applyVoucherCode(
      cart.id,
      code
    )
    dispatch(setCart(updatedCart))

    showNotification(
      i18n.t('cart.notification.applyPromocodeSuccess'),
      'success'
    )
  })

export const createCartFromOrder = (orderId: string) =>
  thunk(async (dispatch, getState, dependencies) => {
    const { cart } = getState().cart

    if (cart?.items.length) {
      const confirmed = await new Promise((resolve) => {
        LYSModal.confirm({
          title: i18n.t('orders.discardCartHint'),
          onOk() {
            resolve(true)
          },
          cancelText: i18n.t('general.cancel'),
          onCancel() {
            resolve(false)
          },
        })
      })

      if (!confirmed) {
        return
      }
    }
    const createdCart = await dependencies.api.cart.createCartFromOrder(orderId)

    dispatch(setCart(createdCart))

    dependencies.cookies.set(CART_ID, createdCart.id)

    dependencies.router.pushAndScrollTop(routes.checkoutAddress.href)
  })
