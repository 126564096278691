import * as React from 'react'
import Newsletter from '@core/components/Newsletter'
import { LYSLayout } from '@core/components/Primitives'
import Footer from '@core/components/Footer/Footer'
import SecondaryFooter from '@core/components/Footer/SecondaryFooter'

const AppFooter: React.FC = () => {
  return (
    <LYSLayout.Footer>
      <Newsletter showCloseButton={false} />
      <Footer />
      <SecondaryFooter />
    </LYSLayout.Footer>
  )
}

export default AppFooter
