import * as React from 'react'
import SmallBenefitsHint from '@core/components/Benefits/SmallBenefitsHint'
import { LYSLayout } from '@core/components/Primitives'
import AppBar from '@core/components/header/AppBar'
import GlobalProgressBar from '@core/components/header/GlobalProgressBar'
import TopBar from '@core/components/header/TopBar'
import PrismicInfoBanner from '@core/prismic/components/PrismicInfoBanner'
import { usePageContext } from '@core/utils/pages/PageContext'
import { HazetShopNavMenu } from '../Navigation/HazetShopNavMenu'
import style from './index.module.less'
import { AppBarContextProvider } from '@core/components/header/AppBar/Context/appBarContext'

const Header: React.FC = () => {
  const {
    props: { isMobile, isTablet, prismicInfoBanner },
  } = usePageContext()
  const isTouchDevice = isMobile || isTablet
  return (
    <LYSLayout.Header className={style.header}>
      {prismicInfoBanner && (
        <PrismicInfoBanner infoBanner={prismicInfoBanner} />
      )}
      <TopBar />
      <AppBarContextProvider>
        <AppBar />
        {!isTouchDevice ? (
          <div className={style.menuBar}>
            <HazetShopNavMenu />
          </div>
        ) : null}
      </AppBarContextProvider>
      <SmallBenefitsHint showCloseButton={false} />
      <GlobalProgressBar className={style.progressBar} />
    </LYSLayout.Header>
  )
}

export default Header
