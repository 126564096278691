import { IconComponent } from '@core/components/Primitives/LYSIcon'
import {
  LinkedinOutlined,
  FacebookOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'

export const contactConfig = {
  name: 'Hazet',
  telephone: '+31 88-1501680',
  get phoneLink() {
    const tel = contactConfig.telephone as string
    return `tel:${tel.replace(/(\s|-)/g, '')}`
  },
  faxNumber: '',
  email: 'webshop@hazet.igefa.nl',
  contactType: 'customer service',
  address: {
    streetAddress: 'Pieter Lieftinckweg 30',
    addressLocality: 'Zaandam',
    postalCode: '1505 HX',
    addressCountry: 'Netherlands',
  },
  social: [
    {
      url: 'https://nl-nl.facebook.com/pages/category/Company/Hazet-142516102486870/',
      icon: FacebookOutlined,
    },
    {
      url: 'https://nl.linkedin.com/company/hazet?original_referer=https%3A%2F%2Fwww.google.com%2F',
      icon: LinkedinOutlined,
    },
    {
      url: 'https://www.youtube.com/channel/UCX-wM0keison7wyXTwTLEAg',
      icon: YoutubeOutlined,
    },
  ] as Array<{ url: string; icon: IconComponent }>,
}
