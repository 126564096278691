import { ColumnCategoryNavigation } from '@core/components/header/Navigation/shared/ColumnCategoryNavigation'
import useCategoryNavigation from '@core/hooks/categories/useCategoryNavigation'
import style from './index.module.less'

export const AssortmentMenu: React.FC = () => {
  const { root } = useCategoryNavigation()

  return (
    <div className={style.assortmentMenu}>
      {root ? (
        <ColumnCategoryNavigation
          categoryRoot={root}
          className={style.categoryNavigation}
        />
      ) : null}
    </div>
  )
}
