import { i18n } from '@core/i18n/i18n'
export * from '@original/config/validators'
import { Callback } from '@original/config/validators'
export default {
  validatePostalCode: (_rule: any, value: any, callback: Callback) => {
    // four digits, optional space and two capitalized letters. E.g.: 1234 EM or 1234EM
    const postalCodePattern = /^[0-9]{4}\s?([A-Za-z]{2})$/
    if (postalCodePattern.test(value)) {
      callback()
    } else {
      callback(i18n.t('form.validation.invalidPostalCode'))
    }
  },
}
