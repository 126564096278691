import { PostalAddress } from 'schema-dts'
import { contactConfig } from './../../core/config/contactConfig'
import config from './config'

const seoConfig = {
  separator: '|',
  image: `${config.baseUrl}/static/assets/hazet/hazet-logo.png`,
  defaultType: 'website',

  organizationDetails: {
    name: contactConfig.name,
    url: config.baseUrl,
    sameAs: [],
    logo: config.baseUrl + config.assets.storeLogo,
    contactPoint: {
      telephone: contactConfig.telephone,
      email: contactConfig.email,
      contactType: 'customer service',
    } as PostalAddress,
    address: {
      streetAddress: contactConfig.address.streetAddress,
      addressLocality: contactConfig.address.addressLocality,
      postalCode: contactConfig.address.postalCode,
      addressCountry: contactConfig.address.addressCountry,
    } as PostalAddress,
  },
}

export default seoConfig
