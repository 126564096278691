import * as React from 'react'
import { CopyrightOutlined } from '@ant-design/icons'
import { LYSIcon, LYSTypography } from '@core/components/Primitives'
import { contactConfig } from '@core/config/contactConfig'
import routes from '@core/config/routes'
import { useTranslation } from '@core/i18n/i18n'
import FooterLink from './FooterLink'
import style from './index.module.less'

const Footer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className={style.secondaryFooter} data-testid={'secondary-footer'}>
      <div>
        <LYSTypography.Text>
          <LYSIcon component={CopyrightOutlined} /> {new Date().getFullYear()}{' '}
          {contactConfig.name}
          {' - '} {t('footer.allRightReservedText')}
        </LYSTypography.Text>
      </div>
      <div className={style.legal}>
        <FooterLink
          {...routes.imprint}
          textLabel={t('footer.impressumText')}
          dataTestId={'footer-impressum-link'}
        />
        <FooterLink
          {...routes.termsAndConditions}
          textLabel={t('footer.conditionsText')}
        />
        <FooterLink
          {...routes.dataProtection}
          textLabel={t('footer.dataProtectionText')}
        />
        <FooterLink
          href="/cookie-policy"
          textLabel={t('footer.cookieSettings')}
        />
      </div>
    </div>
  )
}

export default Footer
