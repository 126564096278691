import React, { useMemo } from 'react'
import { IProductSearch } from '@core/api/Products/types'
import ProductPrice from '@core/components/shared/ProductPrice'
import { useTranslation } from '@core/i18n/i18n'
import { useServices } from '@core/utils/ioc'
import ProductCollectionListItem from '@original/components/ProductCollection/ProductCollectionListItem'
import style from './index.module.less'

interface OwnProps {
  product: IProductSearch
}

const HazetProductCollectionListItem: React.FC<OwnProps> = ({ product }) => {
  const { t } = useTranslation()
  const { productContextService } = useServices()
  const productContext = useMemo(
    () =>
      productContextService.getProductSearchContext(
        product,
        product.mainVariant
      ),
    [productContextService, product]
  )
  return (
    <ProductCollectionListItem
      product={product}
      slots={{
        price: () => {
          return (
            <>
              {product.mainVariant.packagingUnits[0].price && (
                <ProductPrice
                  price={product.mainVariant.packagingUnits[0].price}
                  unitPrice={product.mainVariant.packagingUnits[0].unitPrice}
                  strikePrice={
                    product.mainVariant.packagingUnits[0].strikeThroughPrice
                      ?.price
                  }
                  hideAdditionalCosts
                  showDiscountHint={true}
                />
              )}
              {productContext.rules.displayNoPriceHint && (
                <div className={style.hintContainer}>
                  {t('product.hint.noPrice')}
                </div>
              )}
            </>
          )
        },
      }}
    />
  )
}

export default HazetProductCollectionListItem
