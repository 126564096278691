import React, { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { LYSButton, LYSSpacer } from '@core/components/Primitives'
import { useTranslation } from '@core/i18n/i18n'
import style from './index.module.less'
import LYSStaticImage from '@core/components/Primitives/LYSStaticImage'
import config from '../../../config/config'

interface Props {
  showCloseButton?: boolean
}

const SmallBenefitsHint: React.FC<Props> = ({ showCloseButton }) => {
  const { t } = useTranslation()

  const [showHint, setshowHint] = useState(true)
  const hideHint = () => {
    setshowHint(false)
  }

  return showHint ? (
    <>
      <div className={style.hintContainer}>
        <LYSSpacer />
        <div className={style.points}>
          <div className={style.checkIcon}>
            <LYSStaticImage
              src={config.assets.benefits.deliveryTruck}
              alt={t('benefits.deliveryText')}
              width={25}
              height={16}
            />
          </div>
          <div> {t('benefits.deliveryText')}</div>
        </div>
        <div className={style.points}>
          <div className={style.checkIcon}>
            <LYSStaticImage
              src={config.assets.benefits.support}
              alt={t('benefits.personalAdviceText')}
              width={25}
              height={16}
            />
          </div>
          <div>{t('benefits.personalAdviceText')}</div>
        </div>
        <div className={style.points}>
          <div className={style.checkIcon}>
            <LYSStaticImage
              src={config.assets.benefits.checklist}
              alt={t('benefits.orderingText')}
              width={25}
              height={16}
            />
          </div>
          <div>{t('benefits.orderingText')}</div>
        </div>
        <LYSSpacer />
        {showCloseButton && (
          <LYSButton
            className={style.closeIcon}
            icon={<CloseOutlined rev={undefined} />}
            size="small"
            type={'link'}
            onClick={hideHint}
          />
        )}
      </div>
    </>
  ) : null
}

export default SmallBenefitsHint
