import { createElement } from 'react'
import { MenuOutlined } from '@ant-design/icons'

export interface NavSubMenuConfig {
  id: string
  enabled: boolean
  title: string
  icon?: string
  iconUrl?: string
}

export default {
  assortment: {
    id: 'assortment',
    enabled: true,
    title: 'Alle categorieën',
    icon: createElement(MenuOutlined),
  },
  brands: {
    id: 'brands',
    enabled: true,
    title: 'Merken',
    icon: undefined,
    iconUrl: '/static/assets/hazet/original.svg',
    href: 'https://www.hazetshop.nl/page/merken',
  },
  huismerken: {
    id: 'huismerken',
    enabled: true,
    title: 'Onze huismerken',
    icon: undefined,
    iconUrl: '/static/assets/hazet/huismerken.png',
    href: 'http://www.hazetshop.nl/page/huismerken',
  },
  sustainability: {
    id: 'sustainability',
    enabled: true,
    title: 'Duurzaam assortiment',
    icon: undefined,
    iconUrl: '/static/assets/hazet/nachhaltigkeit.svg',
    href: 'http://www.hazetshop.nl/page/duurzaamheid',
  },
  outlet: {
    id: 'sustainability',
    enabled: true,
    title: 'Outlet',
    icon: undefined,
    href: 'http://www.hazetshop.nl/page/outlet',
  },
  rentalmachines: {
    id: 'rentalmachines',
    enabled: true,
    title: 'Verhuurmachines',
    icon: undefined,
    iconUrl: '/static/assets/hazet/cleaner.svg',
    href: 'https://www.hazetshop.nl/page/verhuurvloot',
  },
}
